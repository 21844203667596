import { useEffect, useState } from "react";

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });

    // Check if the device is not touch-enabled before adding the listener
    const isTouchDevice = !("ontouchstart" in window || navigator.maxTouchPoints > 0);
    if (isTouchDevice) {
      window.addEventListener("mousemove", setFromEvent);
    }

    return () => {
      if (isTouchDevice) {
        window.removeEventListener("mousemove", setFromEvent);
      }
    };
  }, []);

  return position;
};